import { useEffect, useMemo, useState } from 'react'
import { Box, Chip, CircularProgress, MenuItem, Paper, Select, Tab, Typography } from '@mui/material'
import InsightsOutlinedIcon from '@mui/icons-material/InsightsOutlined'
import SummarizeOutlinedIcon from '@mui/icons-material/SummarizeOutlined'
import BalanceOutlinedIcon from '@mui/icons-material/BalanceOutlined'
import GppGoodOutlinedIcon from '@mui/icons-material/GppGoodOutlined'
import CameraFrontOutlinedIcon from '@mui/icons-material/CameraFrontOutlined'
import { useTranslation } from 'react-i18next'
import { buildTimeStringFromTimestamp } from '../../../../../components/common/time/timeHelper'
import { useGetKycCheckQuery, useGetKycPersonChecksQuery } from '../../../../../features/kyc/kycSlice'
import GridItem from '../GridItem'

import styles from './styles.module.scss'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import CheckAnalytics from '../CheckAnalytics'
import CheckSummary from '../CheckSummary'
import CheckLegalDocuments from '../CheckLegalDocuments'
import CheckSecurityPatterns from '../CheckSecurityPatterns'
import { TableChip } from '../../../../../components/utilities/TableChip'

export const CheckDetails = ({ id, checkType, selectedCheck, setSelectedCheck }) => {
  const { t } = useTranslation(['common', 'messages'])
  const [selectedTab, setSelectedTab] = useState('analytics')

  const { data: dataChecks } = useGetKycPersonChecksQuery({ id, type: checkType })
  const checks = dataChecks?.data

  const {
    data: dataCheckDetails,
    isFetching: fetchingCheckDetails,
    isLoading: loadingCheckDetails,
  } = useGetKycCheckQuery({ id: selectedCheck?._id }, { skip: !selectedCheck?._id })
  const checkDetails = dataCheckDetails?.data

  const checkExternalData = useMemo(() => {
    if (Array.isArray(dataCheckDetails?.data.externalData)) return null
    return dataCheckDetails?.data.externalData
  }, [dataCheckDetails?.data])

  const checkImages = useMemo(() => {
    return dataCheckDetails?.data.images
  }, [dataCheckDetails?.data])

  const handleCheckChange = (checkId) => {
    const selectedCheck = checks.find((check) => check._id === checkId)
    setSelectedCheck(selectedCheck)
  }

  useEffect(() => {
    if (checks?.length > 0) {
      setSelectedCheck(checks[0])
    }
  }, [checks])

  useEffect(() => {
    setSelectedTab('analytics')
  }, [checkType])

  return (
    <Paper
      elevation={0}
      className={`${styles.checkDetailsContainer} ${
        (fetchingCheckDetails || loadingCheckDetails) && 'spinner-transition'
      }`}
    >
      {(fetchingCheckDetails || loadingCheckDetails) && (
        <Box className={styles.loadingSpinnerContainer}>
          <CircularProgress />
        </Box>
      )}
      <Box className={styles.gridContainer}>
        <GridItem
          name={t('messages:kyc.checkStatus')}
          value={checkDetails?.status ? t(`messages:kyc.check.status.${checkDetails?.status}`) : '---'}
        />
        <GridItem
          name={t('messages:kyc.checkOutcome')}
          value={
            checkExternalData?.result ? t(`messages:kyc.check.outcome.${checkExternalData.result.outcome}`) : '---'
          }
        />
        <Select
          value={selectedCheck?._id || ''}
          onChange={(e) => handleCheckChange(e.target.value)}
          classes={{ select: styles.MuiOutlinedInputInput }}
        >
          {checks?.length > 0 ? (
            checks.map((item) => (
              <MenuItem key={item._id} value={item._id}>
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-around',
                    alignItems: 'center',
                  }}
                >
                  {buildTimeStringFromTimestamp(item.createdAt)}
                  <Box width="100px">
                    <TableChip action={item.status} noIcon={true} />
                  </Box>
                </Box>
              </MenuItem>
            ))
          ) : (
            <MenuItem disabled value="">
              {t('messages:kyc.noChecks')}
            </MenuItem>
          )}
        </Select>
      </Box>
      {checkDetails && (
        <TabContext value={selectedTab}>
          <Box className={styles.sectionsContainer}>
            <Paper
              elevation={0}
              variant="rootTransparent"
              className={styles.sectionsSelector}
              style={{ height: 'fit-content' }}
            >
              <Typography variant="title">{t('common:sections')}</Typography>
              <TabList
                TabIndicatorProps={{
                  style: { display: 'none' },
                }}
                classes={{ flexContainer: styles.flexContainer }}
                onChange={(e, newValue) => setSelectedTab(newValue)}
              >
                <Tab
                  label={
                    <Chip
                      icon={selectedTab === 'analytics' ? <InsightsOutlinedIcon /> : <></>}
                      label={t('common:analytics')}
                      variant={selectedTab === 'analytics' ? 'sliderSelectedPurple' : 'sliderTransparent'}
                    />
                  }
                  value="analytics"
                />
                {checkType === 'document_check' && (
                  <Tab
                    label={
                      <Chip
                        icon={selectedTab === 'summaryInfo' ? <SummarizeOutlinedIcon /> : <></>}
                        label={t('common:summaryInfo')}
                        variant={selectedTab === 'summaryInfo' ? 'sliderSelectedPurple' : 'sliderTransparent'}
                      />
                    }
                    value="summaryInfo"
                  />
                )}
                {checkType === 'document_check' && (
                  <Tab
                    label={
                      <Chip
                        icon={selectedTab === 'legalDocuments' ? <BalanceOutlinedIcon /> : <></>}
                        label={t('common:legalDocuments')}
                        variant={selectedTab === 'legalDocuments' ? 'sliderSelectedPurple' : 'sliderTransparent'}
                      />
                    }
                    value="legalDocuments"
                  />
                )}
                {checkType === 'document_check' && (
                  <Tab
                    label={
                      <Chip
                        icon={selectedTab === 'securityAndPatterns' ? <GppGoodOutlinedIcon /> : <></>}
                        label={t('common:securityAndPatterns')}
                        variant={selectedTab === 'securityAndPatterns' ? 'sliderSelectedPurple' : 'sliderTransparent'}
                      />
                    }
                    value="securityAndPatterns"
                  />
                )}
                <Tab
                  label={
                    <Chip
                      icon={selectedTab === 'receivedImages' ? <CameraFrontOutlinedIcon /> : <></>}
                      label={t('common:receivedImages')}
                      variant={selectedTab === 'receivedImages' ? 'sliderSelectedPurple' : 'sliderTransparent'}
                    />
                  }
                  value="receivedImages"
                />
              </TabList>
            </Paper>
            <Paper elevation={0} className={styles.sectionInfoContainer}>
              <TabPanel value="analytics" className={styles.sectionInfo}>
                <Typography variant="title">{t('common:analytics')}</Typography>
                <CheckAnalytics data={checkExternalData} />
              </TabPanel>

              {checkType === 'document_check' && (
                <>
                  <TabPanel value="summaryInfo" className={styles.sectionInfo}>
                    <Typography variant="title">{t('common:summaryInfo')}</Typography>
                    <CheckSummary data={checkExternalData} />
                  </TabPanel>
                  <TabPanel value="legalDocuments" className={styles.sectionInfo}>
                    <Typography variant="title">{t('common:legalDocuments')}</Typography>
                    <CheckLegalDocuments data={checkExternalData} />
                  </TabPanel>
                  <TabPanel value="securityAndPatterns" className={styles.sectionInfo}>
                    <Typography variant="title">{t('common:securityAndPatterns')}</Typography>
                    <CheckSecurityPatterns data={checkExternalData} />
                  </TabPanel>
                </>
              )}

              <TabPanel value="receivedImages" className={styles.sectionInfo}>
                <Typography variant="title">{t('common:receivedImages')}</Typography>
                {checkImages?.map((image) => (
                  <Box key={image._id} className="img-pair">
                    <Typography variant="title3">{t(`messages:kyc.check.images.${image.type}`)}</Typography>
                    <img className="received-imgs" src={image.file} alt="Base64" />
                  </Box>
                ))}
              </TabPanel>
            </Paper>
          </Box>
        </TabContext>
      )}
    </Paper>
  )
}
