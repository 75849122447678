import guenoApi from '../../services/guenoApi'

const kycApi = guenoApi.injectEndpoints({
  endpoints: (builder) => ({
    findKycPersons: builder.query({
      query: ({ limit, page, text, status }) => {
        return {
          url: '/kyc/person/search',
          params: {
            limit,
            page,
            text,
            status,
          },
        }
      },
      providesTags: ['kycPerson'],
    }),
    createKycPerson: builder.mutation({
      query: ({ body }) => ({
        url: `/kyc/person`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['kycPerson'],
    }),
    getKycPerson: builder.query({
      query: ({ id }) => {
        return {
          url: `/kyc/person/${id}`,
        }
      },
    }),
    getKycPersonChecks: builder.query({
      query: ({ id, type }) => {
        return {
          url: `/kyc/person/${id}/checks`,
          params: {
            type,
          },
        }
      },
      providesTags: ['kycPersonChecks'],
    }),
    getKycCheck: builder.query({
      query: ({ id }) => {
        return {
          url: `/kyc/check/${id}`,
        }
      },
    }),
    createKycPersonCheck: builder.mutation({
      query: ({ id, body }) => ({
        url: `/kyc/person/${id}/checks`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['kycPersonChecks'],
    }),
    createKycPersonComment: builder.mutation({
      query: ({ id, body }) => ({
        url: `/kyc/person/${id}/comments`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['kycPerson'],
    }),
  }),
})

export const {
  useFindKycPersonsQuery,
  useCreateKycPersonMutation,
  useGetKycPersonQuery,
  useGetKycPersonChecksQuery,
  useGetKycCheckQuery,
  useCreateKycPersonCheckMutation,
  useCreateKycPersonCommentMutation,
} = kycApi
