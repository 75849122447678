import {
  Autocomplete,
  Box,
  Button,
  Fab,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import './amountDateRange.scss'
import { camelCaseFormatter } from '../../../../utilities/formatters/camelCase'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import { t } from 'i18next'
import { mapCodesToNames } from '../../../../utilities/MapCodesToNames'

const resetCurrent = {
  dayInit: 'sunday',
  dayEnd: 'sunday',
  dayTimeInitHours: 0,
  dayTimeInitMinutes: 0,
  dayTimeInitSeconds: 0,
  dayTimeEndHours: 0,
  dayTimeEndMinutes: 0,
  dayTimeEndSeconds: 0,
  currency: '',
  amount: 0,
}

const AmountDateRangePicker = ({ ruleParameters, setRuleParameters, isEditing, propertie, possibleValues }) => {
  const [selectedRanges, setSelectedRanges] = useState([])
  const [change, setChange] = useState(false)
  const [isEditingRange, setIsEditingRange] = useState(false)
  const [editingIndex, setEditingIndex] = useState(null)
  const [currentRange, setCurrentRange] = useState({ ...resetCurrent })
  const [message, setMessage] = useState('')
  const days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday']
  const hours = Array.from({ length: 24 }, (_, index) => index).map((hour) => hour.toString().padStart(2, '0'))
  const minutes = Array.from({ length: 60 }, (_, index) => index).map((minute) => minute.toString().padStart(2, '0'))
  const seconds = Array.from({ length: 60 }, (_, index) => index).map((second) => second.toString().padStart(2, '0'))

  useEffect(() => {
    if (Array.isArray(ruleParameters[propertie]) && ruleParameters[propertie].length !== 0) {
      const aux = ruleParameters[propertie].map((range) => {
        return {
          dayInit: range.dayInit,
          dayEnd: range.dayEnd,
          dayTimeInitHours: range.dayTimeInit?.split(':')[0],
          dayTimeInitMinutes: range.dayTimeInit?.split(':')[1],
          dayTimeInitSeconds: range.dayTimeInit?.split(':')[2],
          dayTimeEndHours: range.dayTimeEnd?.split(':')[0],
          dayTimeEndMinutes: range.dayTimeEnd?.split(':')[1],
          dayTimeEndSeconds: range.dayTimeEnd?.split(':')[2],
          currency: range.currency,
          amount: range.amount,
        }
      })
      setSelectedRanges(aux)
    }
  }, [propertie])

  const handleChange = (key, event) => {
    const aux = { ...currentRange }
    aux[key] = event.target.value
    setCurrentRange({ ...aux })
  }

  const addRange = () => {
    if (!isRangeOverlap(currentRange, -1)) {
      setSelectedRanges([...selectedRanges, currentRange])
      setCurrentRange({ ...resetCurrent })
      setMessage('')
      setChange(true)
    } else {
      setMessage(t('messages:rangeOverlap'))
    }
  }

  const removeWorkingDay = (index) => {
    const updatedRanges = [...selectedRanges]
    updatedRanges.splice(index, 1)
    setSelectedRanges([...updatedRanges])
    setChange(true)
    setMessage('')
  }

  const editWorkingDay = (index, newWorkingDay) => {
    if (!isRangeOverlap(newWorkingDay, index)) {
      const updatedRanges = [...selectedRanges]
      updatedRanges[index] = newWorkingDay
      setSelectedRanges([...updatedRanges])
      setIsEditingRange(false)
      setCurrentRange({ ...resetCurrent })
      setMessage('')
      setChange(true)
    } else {
      setMessage(t('messages:rangeOverlap'))
    }
  }

  const startEditingRange = (index) => {
    setCurrentRange(selectedRanges[index])
    setIsEditingRange(true)
    setEditingIndex(index)
  }

  const getTimeString = (hours, minutes, seconds) => {
    return parseInt(hours) * 60 * 60 + parseInt(minutes) * 60 + parseInt(seconds)
  }

  const isRangeOverlap = (newRange, indexToEdit) => {
    const newRangeDayInit = days.indexOf(newRange.dayInit)
    let newRangeDayEnd = days.indexOf(newRange.dayEnd)

    if (newRangeDayInit > newRangeDayEnd) newRangeDayEnd += 7

    let indexAux = -1
    for (const range of selectedRanges) {
      indexAux++
      const rangeDayInit = days.indexOf(range.dayInit)
      let rangeDayEnd = days.indexOf(range.dayEnd)

      if (rangeDayInit > rangeDayEnd) rangeDayEnd += 7
      if (newRangeDayInit > newRangeDayEnd) newRangeDayEnd += 7

      if (indexAux !== indexToEdit) {
        if (newRangeDayInit < rangeDayInit && newRangeDayEnd > rangeDayEnd) {
          return true
        } else if (newRangeDayInit > rangeDayInit && newRangeDayEnd < rangeDayEnd) {
          return true
        } else if (newRangeDayInit < rangeDayInit && newRangeDayEnd > rangeDayInit && newRangeDayEnd < rangeDayEnd) {
          return true
        } else if (newRangeDayInit > rangeDayInit && newRangeDayEnd > rangeDayEnd && newRangeDayInit < rangeDayEnd) {
          return true
        } else {
          const previousInit = getTimeString(range.dayTimeInitHours, range.dayTimeInitMinutes, range.dayTimeInitSeconds)
          const previousEnd = getTimeString(range.dayTimeEndHours, range.dayTimeEndMinutes, range.dayTimeEndSeconds)
          const newRangeInit = getTimeString(
            newRange.dayTimeInitHours,
            newRange.dayTimeInitMinutes,
            newRange.dayTimeInitSeconds,
          )

          const newRangeEnd = getTimeString(
            newRange.dayTimeEndHours,
            newRange.dayTimeEndMinutes,
            newRange.dayTimeEndSeconds,
          )
          if ((newRangeDayEnd === rangeDayInit || newRangeDayEnd - 7 === rangeDayInit) && newRangeEnd >= previousInit) {
            return true
          } else if (
            (newRangeDayInit === rangeDayEnd || newRangeDayInit === rangeDayEnd - 7) &&
            newRangeInit <= previousEnd
          ) {
            return true
          }
        }
      }
    }

    return false
  }

  useEffect(() => {
    if (change) {
      const all = { ...ruleParameters }
      const finalParam = []
      selectedRanges.forEach((range) => {
        finalParam.push({
          dayInit: range.dayInit,
          dayEnd: range.dayEnd,
          dayTimeInit: `${range.dayTimeInitHours}:${range.dayTimeInitMinutes}:${range.dayTimeInitSeconds}`,
          dayTimeEnd: `${range.dayTimeEndHours}:${range.dayTimeEndMinutes}:${range.dayTimeEndSeconds}`,
          currency: range.currency,
          amount: range.amount,
        })
      })
      all[propertie] = finalParam
      setRuleParameters({ ...all })
      setChange(false)
    }
  }, [selectedRanges])

  return (
    <Box>
      {message !== '' && (
        <Box className="error-drawer">
          <strong>{message}</strong>
        </Box>
      )}

      <Box className="amount-date">
        {isEditingRange && <Typography variant="title2">{t('common:editRange')}</Typography>}
        <Box className="amount-date-row">
          <Box className="amount-date-selected-ranges">
            <InputLabel>{t('common:currency')}:</InputLabel>

            <Autocomplete
              options={possibleValues}
              getOptionLabel={(option) => mapCodesToNames(option)}
              filterSelectedOptions
              value={currentRange.currency}
              disabled={!isEditing}
              onChange={(e, value) => handleChange('currency', value)}
              renderInput={(params) => <TextField {...params} size="small" placeholder={`${t('common:search')}...`} />}
            />
          </Box>
          <Box className="amount-date-selected-ranges amount-date-row-span">
            <InputLabel>{t('common:amount')}:</InputLabel>
            <TextField
              disabled={!isEditing}
              fullWidth
              type="number"
              variant="outlined"
              size="small"
              value={currentRange.amount}
              onChange={(event) => handleChange('amount', event)}
            />
          </Box>
        </Box>

        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Box className="amount-date">
            <Box className="amount-date-row">
              <Box className="amount-date-selected-ranges">
                <InputLabel>{t('common:from')}:</InputLabel>
                <Select
                  disabled={!isEditing}
                  size="small"
                  displayEmpty
                  fullWidth
                  value={currentRange.dayInit}
                  onChange={(event) => handleChange('dayInit', event)}
                >
                  {days &&
                    days.map((name, index) => (
                      <MenuItem key={index} value={name}>
                        {camelCaseFormatter(name)}
                      </MenuItem>
                    ))}
                </Select>
              </Box>
              <Box className="amount-date-selected-ranges">
                <InputLabel>{t('common:atHour')}:</InputLabel>
                <Select
                  disabled={!isEditing}
                  size="small"
                  displayEmpty
                  fullWidth
                  value={currentRange.dayTimeInitHours}
                  onChange={(event) => handleChange('dayTimeInitHours', event)}
                >
                  {hours &&
                    hours.map((name, index) => (
                      <MenuItem key={index} value={index}>
                        {name}
                      </MenuItem>
                    ))}
                </Select>
              </Box>
              <Box className="amount-date-selected-ranges">
                <InputLabel>{t('common:atMinute')}:</InputLabel>
                <Select
                  disabled={!isEditing}
                  size="small"
                  displayEmpty
                  fullWidth
                  value={currentRange.dayTimeInitMinutes}
                  onChange={(event) => handleChange('dayTimeInitMinutes', event)}
                >
                  {minutes &&
                    minutes.map((name, index) => (
                      <MenuItem key={index} value={index}>
                        {name}
                      </MenuItem>
                    ))}
                </Select>
              </Box>
              <Box className="amount-date-selected-ranges">
                <InputLabel>{t('common:atSecond')}:</InputLabel>
                <Select
                  disabled={!isEditing}
                  size="small"
                  displayEmpty
                  fullWidth
                  value={currentRange.dayTimeInitSeconds}
                  onChange={(event) => handleChange('dayTimeInitSeconds', event)}
                >
                  {seconds &&
                    seconds.map((name, index) => (
                      <MenuItem key={index} value={index}>
                        {name}
                      </MenuItem>
                    ))}
                </Select>
              </Box>
            </Box>
            <Box className="amount-date-row">
              <Box className="amount-date-selected-ranges">
                <InputLabel>{t('common:to')}:</InputLabel>
                <Select
                  disabled={!isEditing}
                  size="small"
                  displayEmpty
                  fullWidth
                  value={currentRange.dayEnd}
                  onChange={(event) => handleChange('dayEnd', event)}
                >
                  {days &&
                    days.map((name, index) => (
                      <MenuItem key={index} value={name}>
                        {camelCaseFormatter(name)}
                      </MenuItem>
                    ))}
                </Select>
              </Box>
              <Box className="amount-date-selected-ranges">
                <InputLabel>{t('common:atHour')}:</InputLabel>
                <Select
                  disabled={!isEditing}
                  size="small"
                  displayEmpty
                  fullWidth
                  value={currentRange.dayTimeEndHours}
                  onChange={(event) => handleChange('dayTimeEndHours', event)}
                >
                  {hours &&
                    hours.map((name, index) => (
                      <MenuItem key={index} value={index}>
                        {name}
                      </MenuItem>
                    ))}
                </Select>
              </Box>
              <Box className="amount-date-selected-ranges">
                <InputLabel>{t('common:atMinute')}:</InputLabel>
                <Select
                  disabled={!isEditing}
                  size="small"
                  displayEmpty
                  fullWidth
                  value={currentRange.dayTimeEndMinutes}
                  onChange={(event) => handleChange('dayTimeEndMinutes', event)}
                >
                  {minutes &&
                    minutes.map((name, index) => (
                      <MenuItem key={index} value={index}>
                        {name}
                      </MenuItem>
                    ))}
                </Select>
              </Box>
              <Box className="amount-date-selected-ranges">
                <InputLabel>{t('common:atSecond')}:</InputLabel>
                <Select
                  disabled={!isEditing}
                  size="small"
                  displayEmpty
                  fullWidth
                  value={currentRange.dayTimeEndSeconds}
                  onChange={(event) => handleChange('dayTimeEndSeconds', event)}
                >
                  {seconds &&
                    seconds.map((name, index) => (
                      <MenuItem key={index} value={index}>
                        {name}
                      </MenuItem>
                    ))}
                </Select>
              </Box>
            </Box>
          </Box>

          <Box className="amount-date-button-right">
            {isEditingRange && (
              <Button
                variant="outlinedGrey"
                onClick={() => {
                  setMessage('')
                  setIsEditingRange(false)
                  setCurrentRange({ ...resetCurrent })
                }}
                disabled={!isEditing}
              >
                <Typography variant="title2">{t('common:cancel')}</Typography>
              </Button>
            )}
            <Button
              variant="outlined"
              onClick={() => (isEditingRange ? editWorkingDay(editingIndex, currentRange) : addRange())}
              disabled={!isEditing}
              endIcon={isEditingRange ? <></> : <AddCircleOutlineIcon />}
            >
              <Typography variant="title2">{isEditingRange ? t('common:save') : t('common:addRange')}</Typography>
            </Button>
          </Box>
        </LocalizationProvider>
      </Box>

      {selectedRanges && selectedRanges.length !== 0 && (
        <Box className="amount-date-selected-ranges">
          <Typography variant="title2">{t('common:selectedRanges')}</Typography>

          {selectedRanges.map((range, index) => (
            <Paper
              sx={{ padding: '1rem' }}
              variant="colorPrimary"
              elevation={0}
              className="amount-date-selected-ranges-container"
              key={index}
            >
              <Box className="amount-date-selected-ranges">
                <Box className="amount-date-flex">
                  <Box className="amount-date-selected-ranges-row">
                    <Typography variant={isEditing ? 'title2' : 'subtitle3'}>{t('common:currency')}:</Typography>
                    {range.currency}
                  </Box>

                  <Box className="amount-date-selected-ranges-row">
                    <Typography variant={isEditing ? 'title2' : 'subtitle3'}>{t('common:amount')}:</Typography>$
                    {range.amount}
                  </Box>
                </Box>

                <Box className="amount-date-flex">
                  <Box className="amount-date-selected-ranges-row">
                    <Typography variant={isEditing ? 'title2' : 'subtitle3'}>{t('common:from')}:</Typography>
                    {`${camelCaseFormatter(range.dayInit)} - ${range.dayTimeInitHours
                      ?.toString()
                      .padStart(2, '0')}:${range.dayTimeInitMinutes
                      ?.toString()
                      .padStart(2, '0')}:${range.dayTimeInitSeconds?.toString().padStart(2, '0')}`}
                  </Box>

                  <Box className="amount-date-selected-ranges-row">
                    <Typography variant={isEditing ? 'title2' : 'subtitle3'}>{t('common:to')}:</Typography>
                    {`${camelCaseFormatter(range.dayEnd)} - ${range.dayTimeEndHours
                      ?.toString()
                      .padStart(2, '0')}:${range.dayTimeEndMinutes
                      .toString()
                      .padStart(2, '0')}:${range.dayTimeEndSeconds?.toString().padStart(2, '0')}`}
                  </Box>
                </Box>
              </Box>

              <Box className="amount-date-button-right">
                <Box className="amount-date-flex">
                  {isEditing && (
                    <Fab variant="close" className="icon-fab" onClick={() => startEditingRange(index)}>
                      <EditOutlinedIcon />
                    </Fab>
                  )}
                  {isEditing && (
                    <Fab variant="close" className="icon-fab" onClick={() => removeWorkingDay(index)}>
                      <DeleteOutlineOutlinedIcon />
                    </Fab>
                  )}
                </Box>
              </Box>
            </Paper>
          ))}
        </Box>
      )}
    </Box>
  )
}

export default AmountDateRangePicker
