import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Box, Button, Chip, Modal, Paper, Tab, Typography } from '@mui/material'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import { useTranslation } from 'react-i18next'
import { useGetKycPersonQuery } from '../../../features/kyc/kycSlice'
import { buildTimeStringFromTimestamp } from '../../../components/common/time/timeHelper'
import GridItem from './components/GridItem'
import styles from './styles.module.scss'
import { CheckDetails } from './components/CheckDetails'
import { CreateCommentModal } from './components/CreateCommentModal'
import { CreateCheckModal } from './components/CreateCheckModal'

export const KycPerson = () => {
  const { id } = useParams()
  const { t } = useTranslation(['common', 'messages'])
  const { theme } = useSelector((state) => state.theme)
  const [selectedTab, setSelectedTab] = useState('identity_check')
  const [selectedCheck, setSelectedCheck] = useState()
  const [modalNewCommentOpen, setModalNewCommentOpen] = useState(false)
  const [modalCreateCheckOpen, setModalCreateCheckOpen] = useState(false)

  const { data } = useGetKycPersonQuery({ id })
  const kycPerson = data?.data

  const handleTabChange = (tabValue) => {
    setSelectedCheck(null)
    setSelectedTab(tabValue)
  }

  return (
    <Box className={styles.container}>
      <Box className={styles.fullWidthGrid}>
        <Paper elevation={0} className={`${styles.paperStyle} ${styles.customPaper}`}>
          <Typography variant="h4" className={styles.pageTitle}>
            {kycPerson
              ? `${kycPerson?.firstname.toUpperCase()} ${kycPerson?.lastname.toUpperCase()} (${kycPerson?.email.toUpperCase()})`
              : ''}
          </Typography>
          <Box className={styles.gridItems}>
            <GridItem
              name={t('common:status')}
              value={kycPerson?.status ? t(`messages:kyc.person.status.${kycPerson?.status}`) : '---'}
            />
            <GridItem
              name={t('common:outcome')}
              value={kycPerson?.outcome ? t(`messages:kyc.person.outcome.${kycPerson?.status}`) : '---'}
            />
            <GridItem
              name={t('common:createdAt')}
              value={kycPerson?.createdAt ? buildTimeStringFromTimestamp(kycPerson.createdAt) : '---'}
            />
            <GridItem
              name={t('common:updatedAt')}
              value={kycPerson?.updatedAt ? buildTimeStringFromTimestamp(kycPerson.updatedAt) : '---'}
            />
          </Box>
        </Paper>
        <Box>
          <Button
            className={styles.buttonContainer}
            size="small"
            onClick={() => setModalCreateCheckOpen(true)}
            variant="contained"
          >
            {`${t('common:create')} ${t('common:check')}`}
          </Button>
          <Button
            className={styles.buttonContainer}
            size="small"
            onClick={() => setModalNewCommentOpen(true)}
            variant="outlined"
          >
            {t('common:postComment')}
          </Button>
        </Box>
      </Box>
      <Box className={styles.fullWidthGrid}>
        <Box className={styles.paperStyle}>
          <TabContext value={selectedTab}>
            <TabList
              TabIndicatorProps={{ style: { display: 'none' } }}
              onChange={(e, newValue) => handleTabChange(newValue)}
              className={`${styles.tabList} ${
                theme === 'light' ? styles.tabListScrollbarLight : styles.tabListScrollbarDark
              }`}
              classes={{ flexContainer: styles.tabListFlexContainer }}
            >
              <Tab
                label={
                  <Chip
                    label={t('common:identity')}
                    sx={{ fontSize: 16 }}
                    variant={selectedTab === 'identity_check' ? 'sliderBlack' : 'sliderWhite'}
                  />
                }
                className={styles.tabMaxWidth}
                value="identity_check"
                disableRipple
              />
              <Tab
                label={
                  <Chip
                    label={t('common:document')}
                    sx={{ fontSize: 16 }}
                    variant={selectedTab === 'document_check' ? 'sliderBlack' : 'sliderWhite'}
                  />
                }
                className={styles.tabMaxWidth}
                value="document_check"
                disableRipple
              />
            </TabList>
            <Box>
              <TabPanel value={selectedTab}>
                <CheckDetails
                  id={id}
                  checkType={selectedTab}
                  selectedCheck={selectedCheck}
                  setSelectedCheck={setSelectedCheck}
                />
              </TabPanel>
            </Box>
          </TabContext>
        </Box>
        <Box>
          <Paper elevation={0} className={styles.customPaper}>
            <Typography variant="h4">{t('common:timelineAndActivity')}</Typography>
          </Paper>
        </Box>
      </Box>
      <CreateCommentModal id={id} modalOpen={modalNewCommentOpen} setModalOpen={setModalNewCommentOpen} />
      <CreateCheckModal id={id} modalOpen={modalCreateCheckOpen} setModalOpen={setModalCreateCheckOpen} />
    </Box>
  )
}
